var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "div",
              { staticClass: "col-md-12 col-lg-12" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "작업정보" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.editable &&
                                    _vm.param.mdmSopId &&
                                    !_vm.isRevision,
                                  expression:
                                    "editable && param.mdmSopId && !isRevision",
                                },
                              ],
                              attrs: { label: "개정", icon: "restart_alt" },
                              on: { btnClicked: _vm.SetRevision },
                            }),
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.editable &&
                                    _vm.param.mdmSopId &&
                                    _vm.isRevision,
                                  expression:
                                    "editable && param.mdmSopId && isRevision",
                                },
                              ],
                              attrs: { label: "개정취소", icon: "restart_alt" },
                              on: { btnClicked: _vm.CancelRevision },
                            }),
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.data,
                                    mappingType: _vm.mappingType,
                                    label: "저장",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveSopInfo,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "안전작업표준 작업명",
                              name: "sopName",
                            },
                            model: {
                              value: _vm.data.sopName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "sopName", $$v)
                              },
                              expression: "data.sopName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              type: "edit",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.data.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "plantCd", $$v)
                              },
                              expression: "data.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-process", {
                            attrs: {
                              editable: _vm.editable,
                              label: "단위공정",
                              name: "subProcessCd",
                            },
                            model: {
                              value: _vm.data.subProcessCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "subProcessCd", $$v)
                              },
                              expression: "data.subProcessCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "안전작업표준 관리번호",
                              name: "sopNo",
                            },
                            model: {
                              value: _vm.data.sopNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "sopNo", $$v)
                              },
                              expression: "data.sopNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-select", {
                            attrs: {
                              codeGroupCd: "WORK_CLASS_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "workClassCd",
                              label: "작업구분",
                            },
                            model: {
                              value: _vm.data.workClassCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "workClassCd", $$v)
                              },
                              expression: "data.workClassCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-select", {
                            attrs: {
                              codeGroupCd: "WORK_TYPE_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "workTypeCd",
                              label: "작업유형",
                            },
                            model: {
                              value: _vm.data.workTypeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "workTypeCd", $$v)
                              },
                              expression: "data.workTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-select", {
                            attrs: {
                              codeGroupCd: "WORK_KIND_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "workKindCd",
                              label: "작업형태",
                            },
                            model: {
                              value: _vm.data.workKindCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "workKindCd", $$v)
                              },
                              expression: "data.workKindCd",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { noHeader: true },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              counter: true,
                              maxlength: 200,
                              editable: _vm.editable,
                              rows: 1,
                              label: "작업시 안전주의사항",
                              name: "saftyCaution",
                            },
                            model: {
                              value: _vm.data.saftyCaution,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "saftyCaution", $$v)
                              },
                              expression: "data.saftyCaution",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { noHeader: true },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-select", {
                            attrs: {
                              codeGroupCd: "HAZARD_GRADE_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "hazardGradeCd",
                              label: "위험등급",
                            },
                            model: {
                              value: _vm.data.hazardGradeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "hazardGradeCd", $$v)
                              },
                              expression: "data.hazardGradeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-field", {
                            attrs: {
                              editable: _vm.editable,
                              label: "작업자",
                              name: "workerName",
                            },
                            model: {
                              value: _vm.data.workerName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "workerName", $$v)
                              },
                              expression: "data.workerName",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { noHeader: true },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "안전보호구",
                              name: "saftyShied",
                            },
                            model: {
                              value: _vm.data.saftyShied,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "saftyShied", $$v)
                              },
                              expression: "data.saftyShied",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "관련 유해위험기계기구",
                              name: "col1",
                            },
                            model: {
                              value: _vm.data.col1,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "col1", $$v)
                              },
                              expression: "data.col1",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                        },
                        [
                          _c("c-equip", {
                            attrs: {
                              editable: _vm.editable,
                              label: "관련 설비",
                              processCd: _vm.data.subProcessCd,
                              plantCd: _vm.data.plantCd,
                              name: "col2",
                            },
                            model: {
                              value: _vm.data.col2,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "col2", $$v)
                              },
                              expression: "data.col2",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { noHeader: true },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "적용범위",
                              name: "applyRange",
                            },
                            model: {
                              value: _vm.data.applyRange,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "applyRange", $$v)
                              },
                              expression: "data.applyRange",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "목적",
                              name: "purpose",
                            },
                            model: {
                              value: _vm.data.purpose,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "purpose", $$v)
                              },
                              expression: "data.purpose",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-dept", {
                            attrs: {
                              type: "edit",
                              label: "관리부서1",
                              name: "col15",
                            },
                            model: {
                              value: _vm.data.managementDepts,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "managementDepts", $$v)
                              },
                              expression: "data.managementDepts",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-dept", {
                            attrs: {
                              type: "edit",
                              label: "관리부서2",
                              name: "col16",
                            },
                            model: {
                              value: _vm.data.managementDepts2,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "managementDepts2", $$v)
                              },
                              expression: "data.managementDepts2",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-radio", {
                            attrs: {
                              editable: _vm.editable,
                              comboItems: _vm.useFlagItems,
                              label: "사용여부",
                              name: "useFlag",
                            },
                            model: {
                              value: _vm.data.useFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "useFlag", $$v)
                              },
                              expression: "data.useFlag",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-12 col-lg-12" },
              [
                _vm.param.mdmSopId
                  ? _c(
                      "c-card",
                      {
                        staticClass: "cardClassDetailForm",
                        attrs: { title: "개정", topClass: "topcolor-orange" },
                      },
                      [
                        _c("template", { slot: "card-detail" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                                  },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        required:
                                          _vm.editable && _vm.isRevision,
                                        editable:
                                          _vm.editable && _vm.isRevision,
                                        label: "개정번호",
                                        name: "revisionNum",
                                      },
                                      model: {
                                        value: _vm.data.revisionNum,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "revisionNum", $$v)
                                        },
                                        expression: "data.revisionNum",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                                  },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        editable: false,
                                        label: "개정일시",
                                        name: "regDtStr",
                                      },
                                      model: {
                                        value: _vm.data.regDtStr,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "regDtStr", $$v)
                                        },
                                        expression: "data.regDtStr",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                                  },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        editable: false,
                                        label: "개정자",
                                        name: "regUserName",
                                      },
                                      model: {
                                        value: _vm.data.regUserName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "regUserName", $$v)
                                        },
                                        expression: "data.regUserName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                                  },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        required:
                                          _vm.editable && _vm.isRevision,
                                        editable:
                                          _vm.editable && _vm.isRevision,
                                        label: "개정사유",
                                        name: "revisionContent",
                                      },
                                      model: {
                                        value: _vm.data.revisionContent,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data,
                                            "revisionContent",
                                            $$v
                                          )
                                        },
                                        expression: "data.revisionContent",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                              staticStyle: { "margin-top": "-18px !important" },
                              attrs: { id: "revdiv" },
                            },
                            [
                              _c("c-table", {
                                ref: "revision",
                                attrs: {
                                  title: "개정이력",
                                  tableId: "revision",
                                  topBorderClass: "topcolor-orange",
                                  columns: _vm.gridrev.columns,
                                  data: _vm.gridrev.data,
                                  gridHeight: "150px",
                                  columnSetting: false,
                                  expandAll: false,
                                  isFullScreen: false,
                                  usePaging: false,
                                  hideBottom: true,
                                  filtering: false,
                                },
                                on: { rowClick: _vm.rowClickRev },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }